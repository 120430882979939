@use "colors";

.Theme__Solarized {
  --mine-detonated-background: #{colors.$red};
  --cell-background-flagged-wrong: #{colors.$red};
  --cell-threats-1-color: #{colors.$blue};
  --cell-threats-2-color: #{colors.$green};
  --cell-threats-3-color: #{colors.$red};
  --cell-threats-4-color: #{colors.$violet};
  --cell-threats-5-color: #{colors.$magenta};
  --cell-threats-6-color: #{colors.$cyan};
  --cell-threats-7-color: #{colors.$yellow};
  --cell-threats-8-color: #{colors.$orange};
}
