.FormatNumber {
  white-space: nowrap;
  font-family: Nimbus Roman No9 L, Times New Roman, Times, serif;
}

.LevelChooser {
  background-color: var(--background-color);
}

.LevelChooser input[type="range"][orient="vertical"] {
  writing-mode: bt-lr;
  -webkit-appearance: slider-vertical;
}

.LevelChooser button[role="img"] {
  font-size: 80px;
}

.LevelChooser .LevelChooser__controls {
  grid-auto-flow: column;
  display: grid;
}

.LevelChooser button[type="submit"] {
  background-color: var(--ok);
}

.LevelChooser button[type="reset"] {
  background-color: var(--not-ok);
}

.LevelChooser input:not([type="checkbox"]):not([type="radio"]), .LevelChooser select, .LevelChooser textarea, .LevelChooser form small {
  width: 100%;
  display: block;
}

.LevelChooser label, .LevelChooser fieldset legend {
  display: block;
}

.LevelChooser fieldset {
  border: dashed var(--foreground-base0);
  width: fit-content;
  margin: 2px;
  padding: 2px;
  display: flex;
}

.LevelChooser label.RadioGroup__Option {
  font-size: 80px;
  display: inline-block;
}

.LevelChooser input.GridTypeRadio[type="radio"] {
  display: none;
}

.LevelChooser input.GridTypeRadio[type="radio"] + div {
  fill: var(--not-ok);
}

.LevelChooser input[type="radio"] + * {
  padding: 5px;
}

.LevelChooser .SvgBoard__demo {
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  filter: alpha(opacity= 0);
  transition: opacity .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.LevelChooser .SvgBoard__demo[data-selected] {
  opacity: .25;
}

.LevelChooser .LevelChooser__demos {
  width: 10px;
  height: 10px;
}

.LevelChooser input + .TopologyRadio:before {
  content: "CC";
  border-radius: 50%;
  width: .5em;
  height: .5em;
  transition: transform .18s ease-in-out;
  transform: scale(20);
  box-shadow: inset .5em .5em;
}

ul.NumeralSystemChooser {
  padding: 0;
}

ul.NumeralSystemChooser li {
  unicode-bidi: bidi-override;
  margin: 2px 10px;
  list-style-type: none;
  display: inline-block;
}

ul.NumeralSystemChooser .NumeralSystemChooser__digit {
  text-align: center;
  width: 1.1em;
  display: inline-block;
}

.SvgBoard.SvgBoard__rotated .c .ct {
  transform: rotate(-90deg);
}

.c {
  pointer-events: all;
  user-select: none;
  font-family: monospace, color-emoji;
  font-weight: bold;
}

.c * {
  cursor: var(--board-cursor, crosshair);
}

.c .ct {
  transform-origin: center;
}

.c .cb {
  stroke-width: 0;
  fill: var(--cell-background-open, revert);
}

.c .cc {
  fill: var(--cell-background, revert);
}

.c[data-s="0"] .cc:hover {
  fill: var(--cell-background-hover, revert);
}

.c[data-s="4"] .cb {
  fill: var(--mine-detonated-background, revert);
}

.c[data-s="1"][data-t="0"] {
  pointer-events: none;
}

.c[data-t="1"][data-s="1"] .ct {
  fill: var(--cell-threats-1-color);
  font-size: 1em;
}

.c[data-t="2"][data-s="1"] .ct {
  fill: var(--cell-threats-2-color);
  font-size: 1em;
}

.c[data-t="3"][data-s="1"] .ct {
  fill: var(--cell-threats-3-color);
  font-size: 1em;
}

.c[data-t="4"][data-s="1"] .ct {
  fill: var(--cell-threats-4-color);
  font-size: 1em;
}

.c[data-t="5"][data-s="1"] .ct {
  fill: var(--cell-threats-5-color);
  font-size: 1em;
}

.c[data-t="6"][data-s="1"] .ct {
  fill: var(--cell-threats-6-color);
  font-size: 1em;
}

.c[data-t="7"][data-s="1"] .ct {
  fill: var(--cell-threats-7-color);
  font-size: 1em;
}

.c[data-t="8"][data-s="1"] .ct {
  fill: var(--cell-threats-8-color);
  font-size: 1em;
}

.c:active .cb {
  fill: var(--cell-pressed-color, revert) !important;
}

.SvgBoard[data-s="PAUSED"] .cc, .SvgBoard[data-s="PAUSED"] .cb {
  fill: var(--cell-background, revert) !important;
}

.SvgBoard[data-s="PAUSED"] .ct {
  visibility: hidden;
}

.SvgBoard[data-s="PAUSED"] .c, .SvgBoard[data-s="GAME_OVER"] .c, .SvgBoard[data-s="COMPLETED"] .c {
  pointer-events: none;
}

.SvgBoard[data-s="GAME_OVER"] .c[data-s="1"] .cc, .SvgBoard[data-s="GAME_OVER"] .c[data-s="2"] .cc, .SvgBoard[data-s="GAME_OVER"] .c[data-s="3"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="GAME_OVER"] .c[data-s="0"] .cc, .SvgBoard[data-s="GAME_OVER"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="COMPLETED"] .c[data-s="1"] .cc, .SvgBoard[data-s="COMPLETED"] .c[data-s="2"] .cc, .SvgBoard[data-s="COMPLETED"] .c[data-s="3"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="COMPLETED"] .c[data-s="0"] .cc, .SvgBoard[data-s="COMPLETED"] .c[data-s="4"] .cc, .SvgBoard[data-s="PAUSED"] .c[data-s="1"] .cc, .SvgBoard[data-s="PAUSED"] .c[data-s="2"] .cc, .SvgBoard[data-s="PAUSED"] .c[data-s="3"] .cc, .SvgBoard[data-s="PAUSED"] .c[data-s="0"] .cc, .SvgBoard[data-s="PAUSED"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="INITIALIZED"] .c[data-s="1"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="INITIALIZED"] .c[data-s="2"] .cc, .SvgBoard[data-s="INITIALIZED"] .c[data-s="3"] .cc, .SvgBoard[data-s="INITIALIZED"] .c[data-s="0"] .cc, .SvgBoard[data-s="INITIALIZED"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="NOT_INITIALIZED"] .c[data-s="1"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="NOT_INITIALIZED"] .c[data-s="2"] .cc, .SvgBoard[data-s="NOT_INITIALIZED"] .c[data-s="3"] .cc, .SvgBoard[data-s="NOT_INITIALIZED"] .c[data-s="0"] .cc, .SvgBoard[data-s="NOT_INITIALIZED"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="PLAYING"] .c[data-s="1"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="PLAYING"] .c[data-s="2"] .cc, .SvgBoard[data-s="PLAYING"] .c[data-s="3"] .cc, .SvgBoard[data-s="PLAYING"] .c[data-s="0"] .cc, .SvgBoard[data-s="PLAYING"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="ERROR"] .c[data-s="1"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="ERROR"] .c[data-s="2"] .cc, .SvgBoard[data-s="ERROR"] .c[data-s="3"] .cc, .SvgBoard[data-s="ERROR"] .c[data-s="0"] .cc, .SvgBoard[data-s="ERROR"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="DEMO"] .c[data-s="1"] .cc {
  visibility: hidden;
}

.SvgBoard[data-s="DEMO"] .c[data-s="2"] .cc, .SvgBoard[data-s="DEMO"] .c[data-s="3"] .cc, .SvgBoard[data-s="DEMO"] .c[data-s="0"] .cc, .SvgBoard[data-s="DEMO"] .c[data-s="4"] .cc {
  visibility: visible;
}

.SvgBoard[data-s="GAME_OVER"] .c:not([data-m="t"])[data-s="2"] .cb, .SvgBoard[data-s="GAME_OVER"] .c:not([data-m="t"])[data-s="3"] .cb, .SvgBoard[data-s="DEMO"] .c[data-s="2"]:not([data-m="t"]) .cb {
  fill: var(--cell-background-flagged-wrong, revert);
}

@media (prefers-reduced-motion: no-preference) {
  .SvgBoard[data-r="t"] .c[data-s="4"] use.ct, .SvgBoard[data-r="t"] .c[data-s="4"] image.ct, .SvgBoard[data-r="t"] .c[data-s="4"] text.ct[role="img"] {
    animation: .5s shake_rotated;
  }

  .SvgBoard[data-r="f"] .c[data-s="4"] use.ct, .SvgBoard[data-r="f"] .c[data-s="4"] image.ct, .SvgBoard[data-r="f"] .c[data-s="4"] text.ct[role="img"] {
    animation: .5s shake;
  }

  @keyframes EXPLODED-animation {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1.2);
    }

    50% {
      transform: scale(.5);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes shake_rotated {
    0% {
      transform: translate(1px, 1px)rotate(-90deg);
    }

    10% {
      transform: translate(-1px, -2px)rotate(-91deg);
    }

    20% {
      transform: translate(-3px)rotate(-89deg);
    }

    30% {
      transform: translate(3px, 2px)rotate(-90deg);
    }

    40% {
      transform: translate(1px, -1px)rotate(-89deg);
    }

    50% {
      transform: translate(-1px, 2px)rotate(-91deg);
    }

    60% {
      transform: translate(-3px, 1px)rotate(-90deg);
    }

    70% {
      transform: translate(3px, 1px)rotate(-91deg);
    }

    80% {
      transform: translate(-1px, -1px)rotate(-89deg);
    }

    90% {
      transform: translate(1px, 2px)rotate(-90deg);
    }

    100% {
      transform: translate(1px, -2px)rotate(-91deg);
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px)rotate(0);
    }

    10% {
      transform: translate(-1px, -2px)rotate(-1deg);
    }

    20% {
      transform: translate(-3px)rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px)rotate(0);
    }

    40% {
      transform: translate(1px, -1px)rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px)rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px)rotate(0);
    }

    70% {
      transform: translate(3px, 1px)rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px)rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px)rotate(0);
    }

    100% {
      transform: translate(1px, -2px)rotate(-1deg);
    }
  }
}

.SvgMinesweeper__Container {
  user-select: none;
  text-align: center;
  position: absolute;
  inset: 0;
  overflow: hidden auto;
}

.SvgMinesweeper .SvgBoard[data-s="PLAYING"] .SvgMinesweeper__Controls {
  grid-template-columns: 1fr 2em 1fr 0;
}

.SvgMinesweeper .SvgMinesweeper__Controls {
  cursor: default;
  text-align: left;
  vertical-align: middle;
  grid-auto-flow: column;
  grid-template: revert;
  direction: ltr;
  grid-template-columns: 1fr 4em 1fr 1.2em;
  place-content: space-between;
  min-width: 10px;
  min-height: 10px;
  margin: 5px 5px 2px;
  padding: 5px 5px 2px;
  font-size: 20px;
  font-weight: bold;
  display: grid;
  position: relative;
}

.SvgMinesweeper .SvgMinesweeper__Controls .SvgMinesweeper__Controls__Item {
  text-align: end;
}

.SvgMinesweeper .SvgMinesweeper__Controls summary.SvgMinesweeper__Controls__Item {
  list-style: none;
}

.SvgMinesweeper .SvgMinesweeper__Controls ul.Menu__List {
  background-color: var(--background-base2);
  box-shadow: 0 0 30px -10px var(--foreground-base3);
  border-radius: 10px;
  padding: 15px;
  list-style: none;
  position: absolute;
  right: 0;
}

.SvgMinesweeper .SvgMinesweeper__Controls ul.Menu__List li:hover {
  color: var(--cell-threats-1-color);
}

.SvgMinesweeper .SvgMinesweeper__Controls ul.Menu__List a {
  color: inherit;
  text-decoration: inherit;
}

.SvgMinesweeper .SvgMinesweeper__Controls details {
  display: block;
}

.SvgMinesweeper .SvgMinesweeper__Controls details summary {
  cursor: pointer;
  list-style-type: none;
}

.SvgMinesweeper .SvgMinesweeper__Controls details summary::-webkit-details-marker {
  display: none;
}

.SvgMinesweeper .SvgMinesweeper__Controls details summary::marker {
  display: none;
}

.SvgMinesweeper .SvgMinesweeper__Controls details summary::-moz-list-bullet {
  list-style-type: none;
}

.SvgMinesweeper .SvgMinesweeper__Controls details summary:after {
  display: none;
}

.SvgMinesweeper .SvgMinesweeper__Controls details summary:focus {
  outline: none;
}

.GameOver {
  z-index: 3;
  opacity: .8;
  background-color: #0000;
  align-content: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
}

.Minesweeper[data-s="COMPLETE"] {
  background-color: green !important;
}

.Minesweeper.overlay {
  z-index: 2;
  cursor: pointer;
  background-color: #000000bf;
  place-items: center;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  inset: 0;
}

.Control {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.Page {
  background-color: var(--background-base3, revert);
  color: var(--foreground-base3, revert);
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: auto;
}

@media screen and (width >= 600px) {
  .Page {
    padding: 20px;
  }
}

.SvgBoard {
  fill: #0000;
  border: solid var(--cell-background-open);
}

.c[data-s="4"] {
  animation: .3s linear move;
}

@keyframes move {
  0% {
    text-shadow: 4px -4px red, 3px -3px red, 2px -2px red, 1px -1px red, -4px 4px #0ff, -3px 3px #0ff, -2px 2px #0ff, -1px 1px #0ff;
  }

  25% {
    text-shadow: -4px -4px #0ff, -3px -3px #0ff, -2px -2px #0ff, -1px -1px #0ff, 4px 4px red, 3px 3px red, 2px 2px red, 1px 1px red;
  }

  50% {
    text-shadow: -4px 4px red, -3px 3px red, -2px 2px red, -1px 1px red, 4px -4px #0ff, 3px -3px #0ff, 2px -2px #0ff, 1px -1px #0ff;
  }

  75% {
    text-shadow: 4px 4px #0ff, 3px 3px #0ff, 2px 2px #0ff, 1px 1px #0ff, -4px -4px red, -3px -3px red, -2px -2px red, -1px -1px red;
  }

  100% {
    text-shadow: 4px -4px red, 3px -3px red, 2px -2px red, 1px -1px red, -4px 4px #0ff, -3px 3px #0ff, -2px 2px #0ff, -1px 1px #0ff;
  }
}

:root, .Theme__Default {
  --mine-detonated-background: red;
  --mine-detonated-animation: none;
  --cell-border: var(--cell-background-open);
  --cell-background: lightSlateGray;
  --cell-background-open: #eee;
  --cell-background-flagged-wrong: var(--not-ok);
  --cell-background-hover: var(--not-ok);
  --cell-threats-1-color: DodgerBlue;
  --cell-threats-2-color: ForestGreen;
  --cell-threats-3-color: crimson;
  --cell-threats-4-color: violet;
  --cell-threats-5-color: magenta;
  --cell-threats-6-color: DarkTurquoise;
  --cell-threats-7-color: indigo;
  --cell-threats-8-color: orange;
  --cell-pressed-color: var(--cell-background-open);
  --cell-color: #002b36;
  --cell-mined-color: var(--cell-color);
  --cell-flagged-color: var(--cell-color);
  --board-border-width: 6px;
  --board-padding: 3px;
  --background-base3: white;
  --background-base2: #e0e0e0;
  --background-base1: silver;
  --background-base0: #a0a0a0;
  --foreground-base3: black;
  --foreground-base2: #202020;
  --foreground-base1: #404040;
  --foreground-base0: #606060;
  --ok: var(--cell-threats-2-color);
  --not-ok: var(--cell-threats-3-color);
}

.Theme__Solarized {
  --mine-detonated-background: #dc322f;
  --cell-background-flagged-wrong: #dc322f;
  --cell-threats-1-color: #268bd2;
  --cell-threats-2-color: #859900;
  --cell-threats-3-color: #dc322f;
  --cell-threats-4-color: #6c71c4;
  --cell-threats-5-color: #d33682;
  --cell-threats-6-color: #2aa198;
  --cell-threats-7-color: #b58900;
  --cell-threats-8-color: #cb4b16;
}

.Theme__Solarized__Light {
  color: #002b36;
  --cell-background: #657b83;
  --cell-background-open: #eee8d5;
  --cell-border: #eee8d5;
  --cell-pressed-color: #e8e0c6;
  --background-base3: #fdf6e3;
  --background-base2: #eee8d5;
  --background-base1: #93a1a1;
  --background-base0: #839496;
  --foreground-base3: #002b36;
  --foreground-base2: #073642;
  --foreground-base1: #586e75;
  --foreground-base0: #657b83;
  background-color: #fdf6e3;
}

.Theme__Solarized__Dark {
  color: #fdf6e3;
  --cell-background: #839496;
  --cell-background-open: #073642;
  --cell-border: #073642;
  --cell-pressed-color: #094554;
  --background-base3: #002b36;
  --background-base2: #073642;
  --background-base1: #586e75;
  --background-base0: #657b83;
  --foreground-base3: #fdf6e3;
  --foreground-base2: #eee8d5;
  --foreground-base1: #93a1a1;
  --foreground-base0: #839496;
  background-color: #002b36;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

dl dl, dl ol, dl ul, ol dl, ul dl, ol ol, ol ul, ul ol, ul ul {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, select {
  margin: 0;
}

button {
  text-transform: none;
  overflow: visible;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  padding: .35em .75em .625em;
}

input {
  overflow: visible;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

select {
  text-transform: none;
}

textarea {
  margin: 0;
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted buttontext;
}

:-moz-ui-invalid {
  box-shadow: none;
}

details {
  display: block;
}

dialog {
  color: #000;
  background-color: #fff;
  border: solid;
  width: fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: color-emoji;
  src: local(Noto Color Emoji), local(Apple Color Emoji), local(Segoe UI Emoji), local(Segoe UI Symbol), local(Emoji One), local(Twemoji), local("emoji");
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background, repeating-radial-gradient(var(--background-base3), var(--background-base2), var(--background-base1)));
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, color-emoji;
}

[role="img"] {
  font-family: color-emoji;
}

:root, body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}
/*# sourceMappingURL=index.de6010dc.css.map */
