@use "sass:color";
@use "colors";

.Theme__Solarized__Dark {
  background-color: colors.$base03;
  color: colors.$base3;

  --cell-background: #{colors.$base0};
  --cell-background-open: #{colors.$base02};
  --cell-border: #{colors.$base02};
  --cell-pressed-color: #{color.adjust(colors.$base02, $lightness: 4%)};
  --background-base3: #{colors.$base03};
  --background-base2: #{colors.$base02};
  --background-base1: #{colors.$base01};
  --background-base0: #{colors.$base00};
  --foreground-base3: #{colors.$base3};
  --foreground-base2: #{colors.$base2};
  --foreground-base1: #{colors.$base1};
  --foreground-base0: #{colors.$base0};
}
