.LevelChooser {
input[type="range"][orient=vertical] {
  writing-mode: bt-lr;
  -webkit-appearance: slider-vertical;
}
  background-color: var(--background-color);
  button[role=img] {
    font-size: 80px;
  }
  // Layout
  .LevelChooser__controls {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
  }

  button[type=submit] {
    background-color: var(--ok);
  }

  button[type=reset] {
    background-color: var(--not-ok);
  }

  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea,
  form small {
    display: block;
    width: 100%;
  }
  label,
  fieldset legend {
    display: block;
  }

  fieldset {
    padding: 2px;
    margin: 2px;
    border: dashed var(--foreground-base0);
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
  }

  label.RadioGroup__Option {
    display: inline-block;
    font-size: 80px;
  }

//  .SvgBoard {
//    border-radius: 95%;
//  }

  input.GridTypeRadio[type='radio'] {
    display: none;
  }

/*
  input.GridTypeRadio[type='radio']:checked + * .cc {
    fill: var(--ok);
  }
*/
  input.GridTypeRadio[type='radio'] + div {
    // width: 32px;
    fill: var(--not-ok);
  }

  input[type='radio'] + * {
    padding: 5px;
  }

  .SvgBoard__demo {
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;

    position:absolute;
    left:0;
    top: 0;
    transition: opacity 0.5s ease-in-out;
    filter: alpha(opacity=0);

    &[data-selected] {
      opacity: .25;
    }
  }

  .LevelChooser__demos {
    width: 10px;
    height: 10px;
  }

input + .TopologyRadio::before {
  content: "CC";
  width: 0.5em;
  height: 0.5em;
  box-shadow: inset 0.5em 0.5em currentColor;
  border-radius: 50%;
  transition: 180ms transform ease-in-out;
  transform: scale(20);
}
}
/*
* {
border: none !important;
padding: 0 !important;
margin: 0 !important;
}
*/
